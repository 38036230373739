<template>
  <div class="onepage-wrapper">
    <div class="home-wrapper">
      <img src="@/assets/images/logo.png" alt="">

      <h2>Site em Desenvolvimento</h2>
      <p>Estamos reformulando nosso site para melhor atendê-lo</p>

      <div class="wrapper-contato">
        <div>
          <img class="icon-mail" src="@/assets/images/mail.png" alt="">
          <a href="mailto:atendimento@vitalsegurancadotrabalho.com">atendimento@vitalsegurancadotrabalho.com</a>
        </div>
        <div style="display: flex; justify-content: center; align-items: center;">
          <img class="icon-mail" src="@/assets/images/whatsapp-icon.png" alt="">
          <a href="https://api.whatsapp.com/send?phone=5511975903260" target="_blank">11 97590-3260</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style lang="scss" scoped>
.onepage-wrapper {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,0) 30%, rgba(159,170,174,1) 95%);
  .home-wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      max-width: 100%;
    }

    h2 {
      font-size: 35px;
      margin: 0;
      font-weight: bold;
    }

    p {
      font-size: 16px;
    }

    .icon-mail {
      margin-right: 10px;
      max-width: 25px;
    }

    a {
      color: #000;
      text-decoration: none;
    }
  }
}

@media(max-width: 576px) {
  .onepage-wrapper {
    .home-wrapper {
      h2 {
        font-size: 22px;
        margin: 0;
      }
      p {
        font-size: 14px;
        text-align: center;
      }
      .icon-mail {
        max-width: 12px;
        margin-right: 3px;
      }
      a {
        font-size: 13px;
        text-align: center;
      }
    }
  }
}
</style>
